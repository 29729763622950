<template>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-card class="pa-6">
            <v-card-title class="headline">Your Company Profile</v-card-title>
  
            <!-- URL Input and Generate Description Button -->
            <v-text-field
              v-model="companyUrl"
              label="Company Homepage URL"
              :disabled="isGenerating"
              class="mb-5"
              required
              clearable
              prepend-icon="mdi-web"
              aria-label="Company Homepage URL"
              :rules="[validateUrl]"
            ></v-text-field>
  
            <div class="d-flex justify-center mb-5">
              <v-btn
                :loading="isGenerating"
                :disabled="isGenerating || !companyUrl"
                @click="generateDescription"
                color="primary"
                dark
                medium
                class="mr-3"
                aria-label="Generate Description"
              >
                Generate Description
              </v-btn>
              <!-- Optionally, add a Reset Button -->
              <v-btn
                @click="resetForm"
                color="secondary"
                dark
                medium
                aria-label="Reset Form"
              >
                Reset
              </v-btn>
            </div>
  
            <!-- Display Generated Description -->
            <v-card v-if="generatedDescription" class="mb-5 elevation-2">
              <v-card-text>
                <div class="d-flex justify-space-between align-center mb-3">
                  <strong>Generated Description:</strong>
                  <v-btn
                    @click="acceptGeneratedDescription"
                    color="success"
                    dark
                    small
                    elevation="1"
                    class="accept-button"
                    aria-label="Accept Generated Description"
                  >
                    <v-icon left>mdi-check-circle</v-icon>
                    Accept
                  </v-btn>
                </div>
                <p>{{ generatedDescription }}</p>
              </v-card-text>
            </v-card>
  
            <!-- Company Description Textarea -->
            <v-textarea
              v-model="companyDescription"
              label="Company Description"
              rows="6"
              class="mb-5"
              clearable
              prepend-icon="mdi-format-text"
              aria-label="Company Description"
            ></v-textarea>
  
            <div class="d-flex justify-center">
              <v-btn
                :loading="isLoading"
                @click="saveDescription"
                color="primary"
                dark
                medium
                aria-label="Save Description"
              >
                Save Description
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- Snackbar Notification -->
      <v-snackbar
        v-model="showSnackbar"
        :color="snackbarColor"
        timeout="3000"
        top
        right
        aria-live="polite"
      >
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text @click="showSnackbar = false" v-bind="attrs">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </template>
  
  <script>
  import { useAuth0 } from '@auth0/auth0-vue';
  import axios from 'axios';
  
  export default {
    name: 'CompanyPage',
    data() {
      return {
        companyDescription: '',
        companyUrl: '',
        generatedDescription: '',
        isLoading: false,
        isGenerating: false,
        showSnackbar: false,
        snackbarMessage: '',
        snackbarType: 'success', // 'success' or 'error'
      };
    },
    async mounted() {
      try {
        const token = await this.getAccessTokenSilently();
        const response = await axios.get('/api/company-description', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.companyDescription = response.data.description;
      } catch (error) {
        console.error('Error fetching company description:', error);
        this.showSnackbarNotification('Failed to load company description.', 'error');
      }
    },
    setup() {
      const { getAccessTokenSilently } = useAuth0();
      return { getAccessTokenSilently };
    },
    computed: {
      snackbarColor() {
        return this.snackbarType === 'success' ? 'green' : 'red';
      },
    },
    methods: {
      async saveDescription() {
        this.isLoading = true;
        try {
          const token = await this.getAccessTokenSilently();
          await axios.post(
            '/api/company-description',
            { description: this.companyDescription },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          this.showSnackbarNotification('Company description saved successfully!', 'success');
        } catch (error) {
          console.error('Error saving company description:', error);
          this.showSnackbarNotification('Failed to save company description.', 'error');
        } finally {
          this.isLoading = false;
        }
      },
      async generateDescription() {
        if (!this.companyUrl) return;
        this.isGenerating = true;
        try {
          const response = await axios.post('/api/summarize-company', { url: this.companyUrl });
          this.generatedDescription = response.data.description;
          this.showSnackbarNotification('Description generated successfully!', 'success');
        } catch (error) {
          console.error('Error generating company description:', error);
          this.showSnackbarNotification('Failed to generate description. Please try again.', 'error');
        } finally {
          this.isGenerating = false;
        }
      },
      acceptGeneratedDescription() {
        this.companyDescription = this.generatedDescription;
        this.generatedDescription = '';
        this.showSnackbarNotification('Description accepted!', 'success');
      },
      showSnackbarNotification(message, type) {
        this.snackbarMessage = message;
        this.snackbarType = type;
        this.showSnackbar = true;
        setTimeout(() => {
          this.showSnackbar = false;
        }, 3000);
      },
      validateUrl(value) {
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,})' + // domain name
          '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-zA-Z\\d_]*)?$',
          'i'
        );
        return pattern.test(value) || 'Please enter a valid URL';
      },
      resetForm() {
        this.companyUrl = '';
        this.generatedDescription = '';
        this.companyDescription = '';
        this.showSnackbarNotification('Form has been reset.', 'success');
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add transition for the accept button to draw attention */
  .accept-button {
    transition: transform 0.2s;
  }
  .accept-button:hover {
    transform: scale(1.05);
  }
  
  /* Enhance card elevation on hover */
  .v-card.elevation-2:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive adjustments if needed */
  @media (max-width: 600px) {
    .v-card {
      padding: 16px;
    }
  }
  </style>
  